import React from 'react';
import styled, { css } from 'styled-components';

import { Icon as IconBase } from 'components/Icon';
import { Product } from 'database';
import { FactorDetail } from 'lib/utils';

const Container = styled.div`
  align-items: center;
  display: flex;
`;

const Icon = styled(IconBase)(
  ({ theme }) => css`
    fill: ${theme.color.blue[400]};
  `
);

const FactorDescription = styled.span`
  color: #000;
`;

export const FactorInfo: React.FC<{ factor: Product.Factor; showDescription?: boolean }> = ({
  factor,
  showDescription = true
}) => {
  const { description } = FactorDetail[factor];

  return (
    <Container>
      {/* eslint-disable-next-line  react/jsx-props-no-spreading */}
      <Icon name="arrow-forward-outline" />
      {showDescription && <FactorDescription>{description}</FactorDescription>}
    </Container>
  );
};
