import { graphql } from 'gatsby';
import React from 'react';

import { SingleProductPage } from 'views/SingleProductPage';

export default function ProductTemplate({ data }): React.ReactNode {
  const { categories } = data.allSitePage.edges[0].node.context;
  const { product } = data.allSitePage.edges[0].node.context;

  return <SingleProductPage categories={categories} product={product} />;
}

export const query = graphql`
  query($path: String!) {
    allSitePage(filter: { path: { eq: $path } }) {
      edges {
        node {
          context {
            categories {
              id
              title
              description
              shortDescription
              slug
              isMain
              type
            }
            product {
              slug
              categories
              name
              shortDescription
              description
              isOnMainPage
              images {
                src
              }
              factors
              plate
              technologies
            }
          }
        }
      }
    }
  }
`;
