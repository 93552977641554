import styled, { css } from 'styled-components';

const Container = styled.section(
  ({ theme }) => css`
    display: grid;
    grid-gap: ${theme.spacing.big};
    grid-template-columns: 1fr;
    width: 100%;

    ${theme.onScreen('TABLET')} {
      grid-column-gap: ${theme.spacing.huge};
      grid-template-columns: 1fr 1fr;
      margin-top: ${theme.spacing.big};
    }
  `
);

const Wrapper = styled.div(
  ({ theme }) => css`
    align-self: center;
    display: flex;
    flex-direction: column;
    padding: ${theme.spacing.big};
    width: 100%;

    ${theme.onScreen('SMALL_DESKTOP')} {
      padding: 0;
      width: 1180px;
    }
  `
);

const Content = styled.main(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;

    h2 {
      font-weight: 600;
      text-transform: uppercase;
      margin-bottom: ${theme.spacing.mid};
      margin-top: ${theme.spacing.big};

      &:first-child {
        margin-top: 0;
      }
    }

    ${theme.onScreen('TABLET')} {
      grid-column: 2;
      grid-row: 1 / 5;
    }
  `
);

const Header = styled.header(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing.big};

    h1 {
      color: rgba(0, 0, 0, 0.8);
      font-size: 1.5rem;
      font-weight: 600;
      margin-bottom: ${theme.spacing.mid};
    }
  `
);

const Tags = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    & > * {
      margin-bottom: ${theme.spacing.small};
      margin-right: ${theme.spacing.small};
    }
  `
);

const Description = styled.p(
  ({ theme }) => css`
    line-height: 1.5;
    margin-bottom: ${theme.spacing.big};
  `
);

const Details = styled.div(
  ({ theme }) => css`
    ul {
      margin-bottom: ${theme.spacing.big};

      li {
        margin-bottom: ${theme.spacing.mid};
        svg {
          margin-right: ${theme.spacing.mid};
        }
      }

      &:nth-child(2) {
        li {
          font-weight: 500;
          &::after {
            content: attr(data-value);
            font-weight: 400;
          }
        }
      }
    }
  `
);

const Preview = styled.img(
  ({ theme }) => css`
    align-items: flex-start;
    display: flex;
    justify-content: center;
    width: 100%;

    ${theme.onScreen('TABLET')} {
      grid-column: 1;
      grid-row: auto;
    }
  `
);

const Categories = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;

    a {
      margin-bottom: ${theme.spacing.mid};
    }

    ${theme.onScreen('TABLET_LANDSCAPE')} {
      flex-direction: row;
      flex-wrap: wrap;

      a {
        margin: 0;
        margin-right: ${theme.spacing.mid};
      }
    }
  `
);

export const SingleProductElements = {
  Wrapper,
  Container,
  Content,
  Header,
  Preview,
  Description,
  Tags,
  Details,
  Categories
};
