import React from 'react';
import ReactTooltip from 'react-tooltip';

import { Breadcrumbs } from 'components/Breadcrumbs';
import { OutlinedButton } from 'components/Button';
import { Tag } from 'components/Tag';
import { Offer, Product } from 'database';
import Layout from 'layouts';
import { useProductImage } from 'lib/hooks';
import { SEO } from 'SEO';

import { SingleOfferPageElements } from '../SingleOfferPage/elements';
import { FactorInfo } from './components/FactorInfo';
import { SingleProductElements } from './elements';

const { Wrapper, Tags, Content, Container, Header, Preview, Description, Details, Categories } = SingleProductElements;
const { PageHeading } = SingleOfferPageElements;

const SingleProductContent: React.FC<{ product: Product.Data; categories: Offer.Data[] }> = ({
  product,
  categories
}) => {
  const image = useProductImage(product.slug);

  return (
    <Wrapper>
      <PageHeading>{product.name}</PageHeading>
      <Breadcrumbs
        crumbs={[
          { display: 'Oferta', link: `/oferta/` },
          { display: categories[0].title, link: `/oferta/${categories[0].slug}/` },
          {
            display: `${product.name.slice(0, 50)}${product.name.length > 50 ? '...' : ''}`,
            link: `/oferta/${categories[0].slug}/${product.slug}/`
          }
        ]}
      />
      <ReactTooltip effect="solid" place="bottom" />
      <Container>
        <Preview src={image.fluid} alt={`Obraz produktu ${product.name}`} />
        <Content>
          <Header>
            <Tags>
              {product.technologies.map((tech) => (
                <Tag key={tech}>{Product.Technology[tech]}</Tag>
              ))}
              <Tag>{Product.Plate[product.plate]}</Tag>
            </Tags>
          </Header>
          <h2>Opis</h2>
          <Description>{product.description}</Description>
          {product.factors.length > 0 && (
            <>
              <h2>Specyfikacja</h2>
              <Details>
                <ul>
                  {product.factors.map((factor) => (
                    <li key={Product.Factor[factor]}>
                      <FactorInfo factor={Product.Factor[factor]} />
                    </li>
                  ))}
                </ul>
              </Details>
            </>
          )}
          <h2>Zobacz więcej w kategorii</h2>
          <Categories>
            {categories.map((cat) => (
              <OutlinedButton key={cat.slug} href={`/oferta/${cat.slug}/`}>
                {cat.title}
              </OutlinedButton>
            ))}
          </Categories>
        </Content>
      </Container>
    </Wrapper>
  );
};
export const SingleProductPage: React.FC<{ product: Product.Data; categories: Offer.Data[] }> = ({
  product,
  categories
}) => (
  <Layout>
    <SEO title={product.name} canonicalHref={`/oferta/${categories[0].slug}/${product.slug}/`} />
    <SingleProductContent product={product} categories={categories} />
  </Layout>
);
